<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" style="width: 100%;">
    <div style="padding-top: 16%;">
      <el-form :inline="false" class="form text-input-password" style="text-align: left;">
        <el-form-item label="原密码">
          <el-input show-password v-model="item.oldPassword" placeholder="输入原始密码" size="small"></el-input>
        </el-form-item>

        <el-form-item label="新密码">
          <el-input show-password v-model="item.newPassword" placeholder="输入新密码" size="small"></el-input>
        </el-form-item>

        <el-form-item label="重复新密码">
          <el-input show-password v-model="item.newPasswordTwo" placeholder="输入新密码" size="small"></el-input>
        </el-form-item>

        <el-form-item>
          <van-button class="confirm-button" @click.prevent="submit">确 定</van-button>
        </el-form-item>
        <van-button class="cancel-button" @click.prevent="cancel_change">取 消</van-button>

      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "new_password",
  data() {
    return {
      fullscreenLoading: false,
      item: {
        user_id: null,
        oldPassword: null,
        newPassword: null,
        newPasswordTwo: null,
      },
      passwordType: 'password',
      districtList: []
    }
  },
  mounted() {
    document.title = "修改密码";
    this.item.user_id = localStorage.getItem('merchantId');
  },
  methods: {
    submit() {
      let _this = this
      if (this.item.oldPassword === null || this.item.oldPassword === "") {
        _this.$message.error('请输入原密码')
        return false
      }
      if (this.item.newPassword === null || this.item.newPassword === "") {
        _this.$message.error('请输入新密码')
        return false
      }
      if (this.item.newPassword !== this.item.newPasswordTwo) {
        _this.$message.error('新密码两次输入不一致')
        return false
      }
      if (this.item.newPassword.length < 6) {
        _this.$message.error('密码至少为6位')
        return false
      }
      _this.fullscreenLoading = true
      let params = {
        oldPassword: _this.$md5(_this.item.oldPassword),
        newPasswordConfirm: _this.$md5(_this.item.newPasswordTwo),
        newPassword: _this.$md5(_this.item.newPassword)
      }
      Http.post('frontend/merchant/changePassword', params).then(result => {
        if (result.code == 200) {
          _this.$message.success('密码修改成功')
          _this.fullscreenLoading = false
          this.$store.commit('logout')
          this.$router.push({path: '/h5/login'})
        } else {
          _this.$message.error(result.message || '密码修改失败')
          _this.fullscreenLoading = false
        }
      }).catch(error => {
        this.$message.error('密码修改失败，请稍后再试')
      }).finally(() => {
        this.fullscreenLoading = false
      })
    },
    cancel_change() {
      this.$router.back();
    },
  },
}
</script>

<style scoped>

.text-input-password {
  padding: 6vw;
}

.confirm-button {
  width: 230px;
  height: 40px;
  background: #E9BE3E;
  border: 2px solid #E9BE3E;
  border-radius: 20px;
  font-size: 18px;
  font-family: PingFang SC;
  margin-left: calc(44vw - 115px);
  font-weight: 400;
  color: #FFFFFF;
  line-height: 15px;
  margin-top: 32px;
}

.cancel-button {
  width: 230px;
  height: 40px;
  background: transparent;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  margin-left: calc(44vw - 115px);
  font-family: PingFang SC;
  font-weight: 400;
  color: #2069BE;
  line-height: 15px;
  margin-top: 2px;
}
</style>